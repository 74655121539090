@import '~@cupcake-ds/cupcake/dist/cupcake-cherry.min.css';

@import 'common/base';

.c-clearfix {
  clear: both;
  position: relative;
}

// These are fixes to support @ipreo/cupcake-components in Cupcake 2.0
cupcake-modal-default-template {
  & + .c-modal-backdrop.c-modal-fade,
  .c-modal-fade {
    opacity: 0;
    transition: opacity 0.35s;
    &.c-modal-in {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
      opacity: 1;
    }
  }

  .c-modal-dialog {
    margin: 30px auto;
    max-width: 600px;
    .c-modal-content {
      text-align: left;
      padding: 0;
    }
    .c-modal-header {
      padding: 12px 20px;
    }
    .c-modal-body {
      padding: 20px;
      p {
        margin: 1em 0;
      }
    }
  }
}

.c-popover {
  position: absolute;
  text-align: left;
  max-width: 300px;
  padding: 6px 10px;
  font-size: 14px;
  font-family: Arial, Helvetica, Sans-Serif;
  line-height: 15px;
  color: #495057;
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.c-popover-bottom,
.c-popover[x-placement^='bottom'] {
  margin-top: 10px;
}

.c-popover-right,
.c-popover[x-placement^='right'] {
  margin-left: 10px;
}

.c-popover-arrow {
  position: absolute;
}

.c-popover[x-placement^='bottom'] > .c-popover-arrow,
.c-popover[x-placement^='top'] > .c-popover-arrow {
  margin: 0 3px;
}

.c-popover[x-placement^='left'] > .c-popover-arrow,
.c-popover[x-placement^='right'] > .c-popover-arrow {
  margin: 3px 0;
}

.c-popover-bottom > .c-popover-arrow,
.c-popover[x-placement^='bottom'] > .c-popover-arrow {
  top: -10px;
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: 10px inset;
  border-color: transparent transparent #ced4da;
  border-bottom-style: solid;
  border-top-width: 0;
}

.c-popover-bottom > .c-popover-arrow::after,
.c-popover[x-placement^='bottom'] > .c-popover-arrow::after {
  top: 1px;
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: 9px inset;
  border-color: transparent transparent #fff;
  border-bottom-style: solid;
  border-top-width: 0;
}

.c-popover-right > .c-popover-arrow,
.c-popover[x-placement^='right'] > .c-popover-arrow {
  left: -10px;
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: inset 10px;
  border-color: transparent #ced4da transparent transparent;
  border-right-style: solid;
  border-left-width: 0;
}

.c-popover-right > .c-popover-arrow::after,
.c-popover[x-placement^='right'] > .c-popover-arrow::after {
  left: 1px;
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: inset 9px;
  border-color: transparent #fff transparent transparent;
  border-right-style: solid;
  border-left-width: 0;
}

.c-popover[x-placement^='bottom'] > .c-popover-arrow::after,
.c-popover[x-placement^='top'] > .c-popover-arrow::after {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.c-popover[x-placement^='left'] > .c-popover-arrow::after,
.c-popover[x-placement^='right'] > .c-popover-arrow::after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
